<div class="flex-middle icon-row">
  <!-- ICON -->
  <ui-kit-icon
    [ngClass]="{ enabled: isEnabled, disabled: !isEnabled }"
    [icon]="isEnabled ? 'check-circle' : 'slash'"
    [size]="20" />

  <!-- LABEL -->
  <span>{{ labels?.[isEnabled ? 'enabled' : 'disabled'] | translate }}</span>
</div>
