export const CalendarWithNumber = `<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon">
    <rect id="Rectangle 164" x="1.8335" y="5.48047" width="33" height="33" rx="3.93" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 333" d="M1.8335 14.4805H34.8335" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 334" d="M25.8335 9.98047V0.980469" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 335" d="M10.8335 9.98047V0.980469" stroke="currentColor" stroke-width="3"/>
    <path id="Vector" d="M23.4235 33.9804C22.6726 33.9756 21.9478 33.8497 21.2491 33.6025C20.5552 33.3554 19.9326 32.9538 19.3812 32.3977C18.8299 31.8416 18.3926 31.1049 18.0695 30.1876C17.751 29.2703 17.5918 28.1439 17.5918 26.8084C17.5965 25.5821 17.7368 24.4866 18.0124 23.5218C18.2928 22.5522 18.6921 21.73 19.2101 21.0551C19.7329 20.3802 20.3579 19.8669 21.0851 19.5152C21.8123 19.1587 22.6274 18.9805 23.5305 18.9805C24.5048 18.9805 25.365 19.1706 26.1112 19.5508C26.8574 19.9263 27.4563 20.4372 27.9078 21.0836C28.3641 21.73 28.6397 22.4548 28.7348 23.258H25.6906C25.5718 22.7495 25.3223 22.3502 24.942 22.0603C24.5618 21.7704 24.0913 21.6254 23.5305 21.6254C22.5799 21.6254 21.8575 22.0389 21.3632 22.8659C20.8736 23.6929 20.6241 24.8169 20.6146 26.238H20.7144C20.933 25.8055 21.2277 25.4372 21.5984 25.133C21.9739 24.8241 22.3993 24.5888 22.8746 24.4272C23.3546 24.2609 23.8608 24.1777 24.3931 24.1777C25.2581 24.1777 26.0281 24.3821 26.703 24.7908C27.3779 25.1948 27.9102 25.7509 28.2999 26.459C28.6896 27.1672 28.8845 27.9776 28.8845 28.8901C28.8845 29.8787 28.654 30.758 28.193 31.5279C27.7367 32.2979 27.0975 32.9015 26.2752 33.3388C25.4577 33.7713 24.5072 33.9851 23.4235 33.9804ZM23.4093 31.5564C23.8845 31.5564 24.3099 31.4424 24.6854 31.2142C25.0609 30.9861 25.3555 30.6772 25.5694 30.2874C25.7833 29.8977 25.8902 29.4605 25.8902 28.9757C25.8902 28.4909 25.7833 28.056 25.5694 27.671C25.3603 27.286 25.0704 26.9795 24.6996 26.7513C24.3289 26.5232 23.9059 26.4091 23.4306 26.4091C23.0742 26.4091 22.7439 26.4757 22.4397 26.6088C22.1403 26.7418 21.8765 26.9272 21.6483 27.1648C21.425 27.4025 21.2491 27.6781 21.1208 27.9918C20.9924 28.3008 20.9283 28.6311 20.9283 28.9828C20.9283 29.4533 21.0352 29.8835 21.2491 30.2732C21.4677 30.6629 21.7624 30.9742 22.1331 31.2071C22.5086 31.44 22.934 31.5564 23.4093 31.5564Z" fill="currentColor"/>
    <path id="Vector_2" d="M14.3496 19.1816V33.7823H11.2627V22.1118H11.1771L7.8335 24.2078V21.4701L11.448 19.1816H14.3496Z" fill="currentColor"/>
  </g>
</svg>
`;
