export enum GuestListExportField {
  ADDRESS = 'address',
  BARCODE = 'barcode',
  CITY = 'city',
  COUNTRY = 'country',
  DISCOUNT_CODE = 'discountCode',
  DISCOUNT_NAME = 'discountName',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  HOLDER_EMAIL = 'holder_email',
  HOLDER_FIRST_NAME = 'holder_firstname',
  HOLDER_LAST_NAME = 'holder_lastname',
  LAST_NAME = 'lastName',
  NAME = 'name',
  ORIGINAL_PRICE = 'originalPrice',
  PAID_AMOUNT = 'paidAmount',
  PHONE = 'phone',
  PRICE_CATEGORY_NAME = 'priceCategoryName',
  PURCHASE_DATE = 'purchaseDate',
  TRANSACTION_ID = 'transactionId',
  ZIPCODE = 'zipCode',
}
