import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostBinding, inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UiKitPalette } from '@ui-kit/enums';
import { take } from 'rxjs/operators';
import { UiKitModalStyleHandlerDirective } from '../../directives/ui-kit-modal-style-handler.directive';

@Component({
  selector: 'ui-kit-modal',
  templateUrl: './ui-kit-modal.component.html',
  styleUrls: ['./ui-kit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [UiKitModalStyleHandlerDirective],
})
export class UiKitModalComponent implements OnInit {

  private modalStyleHandler = inject(UiKitModalStyleHandlerDirective, { optional: true });

  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Input() closeable? = true;
  @Input() palette? = UiKitPalette.PINK;
  @Input() useGridAroundContent = true;
  @Input() @HostBinding('class.fill-container') fillContentContainer? = false;
  @Input() fullHeightContent? = false;
  @Input() internalScrollContainer? = false;
  @Input() @HostBinding('class.overlayed-loading') isOverlayedLoading = false;

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onReady: EventEmitter<void> = new EventEmitter();

  @ContentChild('subtitleTemplate') subtitleTemplate: TemplateRef<any>;
  @ContentChild('actions') actionsTemplate: TemplateRef<any>;

  ngOnInit(): void {
    this.subscribeToStyleHandlerReadyState();
  }

  onXClicked(): void {
    this.onCancel.emit();
  }

  private subscribeToStyleHandlerReadyState(): void {
    if (this.modalStyleHandler) {
      this.modalStyleHandler.onReady
        .pipe(take(1))
        .subscribe(() => this.onReady.emit());
    }
  }

}
