export const TicketWeb = `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon">
    <g id="icon_2">
      <path id="Vector 461"
        d="M20.75 11V14C20.75 14.4142 20.4142 14.75 20 14.75H2C1.58579 14.75 1.25 14.4142 1.25 14V2C1.25 1.58579 1.58579 1.25 2 1.25H6.5"
        stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 462" d="M5 19.25H17" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 463" d="M13.25 14.75V19.25" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 464" d="M8.75 14.75V19.25" stroke="currentColor" stroke-width="1.5" />
    </g>
    <g id="Group 137">
      <path id="Vector 61"
        d="M20.75 6.6351V8.75H8.75003V6.63509L8.99407 6.57877C9.72923 6.40912 10.25 5.75449 10.25 5C10.25 4.24551 9.72923 3.59088 8.99406 3.42123L8.75001 3.36491L8.75003 1.25H20.75V3.3649L20.5059 3.42123C19.7708 3.59088 19.25 4.24551 19.25 5C19.25 5.75449 19.7708 6.40912 20.5059 6.57877L20.75 6.6351ZM8.75 3.52171V3.52171V3.52171Z"
        stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 140" d="M16.7749 0.5L16.7749 2.57692" stroke="currentColor"
        stroke-width="1.5" />
      <path id="Vector 141" d="M16.7749 3.26953V6.03876" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 142" d="M16.7749 6.73047V9.4997" stroke="currentColor" stroke-width="1.5" />
    </g>
  </g>
</svg>`;
