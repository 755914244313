<div
  class="event-type-wrapper ui-kit-palette-{{ palette() }}"
  [ngClass]="{
    'sidebar-mode': displayMode() === 'sidebar',
    'event-list-item-mode': displayMode() === 'event-list-item',
    'bold': isBold()
  }">

  <div class="event-type-display">
    <ui-kit-icon [icon]="icon()" [size]="16"></ui-kit-icon>
    <span class="type-name">
      {{ 'event_type_v2_' + normalizedEventType() | translate }}
    </span>
  </div>

  <div *ngIf="infoMessageLabel() && displayMode() !== 'event-list-item'" class="info-message">
    {{ infoMessageLabel() | translate }}
  </div>
</div>
