import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UtilArray } from '@interticket/core';
import { IUiKitConfirmModalData, UiKitConfirmModalComponent } from '@ui-kit/elements';
import { UiKitSize } from '@ui-kit/enums';
import { ICalendarNewBookingsHeader } from 'app/services/bookings/interfaces/calendar-create-booking.interface';
import { IWorkBreaksHeader } from 'app/services/bookings/interfaces/work-breaks.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { BookingChooserDialogComponent } from '../components/booking-chooser-dialog/booking-chooser-dialog.component';
import { BookingDialogComponent } from '../components/booking-dialog/booking-dialog.component';
import { BookingWorkBreakDialogComponent } from '../components/booking-work-break-dialog/booking-work-break-dialog.component';
import { ConfirmDialogComponent } from '../components/confirm/confirm-dialog.component';
import { ExportFieldDialogComponent } from '../components/export-field-dialog/export-field-dialog.component';
import { ImageDialogComponent } from '../components/image-dialog/image-dialog.component';
import { IExportFieldDialogConfig } from '../interfaces/export-field-dialog-config.interface';
import { ISideDialogOptions } from '../interfaces/side-dialog-options.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private dialog: MatDialog,
  ) { }

  get matDialog(): MatDialog {
    return this.dialog;
  }

  openUiKitConfirmDialog(data: IUiKitConfirmModalData): MatDialogRef<UiKitConfirmModalComponent, IUiKitConfirmModalData> {
    return this.dialog.open<UiKitConfirmModalComponent, IUiKitConfirmModalData>(UiKitConfirmModalComponent, { data });
  }

  getBaseDataForUiKitConfirmDialog(data: Partial<IUiKitConfirmModalData> = {}): IUiKitConfirmModalData {
    const isLoading$ = new BehaviorSubject<boolean>(false);
    const confirmClicked$ = new Subject<void>();
    const confirmed$ = new Subject<boolean>();

    return {
      ...data,
      message: 'label_you_sure_to_delete',
      confirmButtonLabel: 'general_lang_yes',
      dismissButtonLabel: 'general_lang_no',
      confirmClicked$,
      confirmed$,
      isLoading$,
    };
  }

  openConfirmDialog(callback: (confirm: boolean) => void, dialogConfig?: Partial<MatDialogConfig>): MatDialogRef<ConfirmDialogComponent> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      ...dialogConfig,
      data: { title: dialogConfig?.data?.title || 'label_you_sure_to_delete', description: dialogConfig?.data?.description },
    });

    dialogRef.afterClosed()
      .subscribe({
        next: (result: boolean) => callback(result),
      });

    return dialogRef;
  }

  openUiKitSideDialog<T, D = any, R = any>(component: ComponentType<T>, config?: MatDialogConfig<D>, options?: ISideDialogOptions): MatDialogRef<T, R> {
    const withoutActions = options?.withoutActions ?? false;
    const size = options?.size ?? UiKitSize.MEDIUM;
    const disableClose = options?.disableClose ?? false;

    const panelClass = ['ui-kit-modal-panel-side', `ui-kit-modal-panel-size-${size.toLowerCase()}`];

    if (withoutActions) {
      panelClass.push('ui-kit-modal-panel-without-actions');
    }

    if (options?.panelClass) {
      panelClass.push(...UtilArray.arrayify(options.panelClass));
    }

    const sideDialogConfig: Partial<MatDialogConfig> = {
      position: { right: '0px' },
      panelClass,
      enterAnimationDuration: 300,
      exitAnimationDuration: 300,
      disableClose,
    };

    const dialogConfig = { ...config, ...sideDialogConfig };

    return this.dialog.open(component, dialogConfig);
  }

  openExportFieldDialog<T>(dialogConfig?: Partial<MatDialogConfig<IExportFieldDialogConfig<T>>>): MatDialogRef<ExportFieldDialogComponent<T>> {
    return this.dialog.open<ExportFieldDialogComponent<T>>(ExportFieldDialogComponent, dialogConfig);
  }

  openImageDialog(dialogConfig?: Partial<MatDialogConfig>): MatDialogRef<ImageDialogComponent> {
    return this.dialog.open(ImageDialogComponent, {
      ...dialogConfig,
    });
  }

  openBookingDialog(callback: (dialogData: { confirmed: boolean; formData: ICalendarNewBookingsHeader }) => void, dialogConfig?: Partial<MatDialogConfig>): MatDialogRef<BookingDialogComponent> {
    const dialogRef = this.dialog.open(BookingDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe({
        next: (dialogData: { confirmed: boolean; formData: ICalendarNewBookingsHeader }) => callback(dialogData),
      });

    return dialogRef;
  }

  openBookingChooserDialog(
    callback: (dialogData: { confirmed: boolean; formData: { createType: string } }) => void,
    dialogConfig?: Partial<MatDialogConfig>,
  ): MatDialogRef<BookingChooserDialogComponent> {

    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(BookingChooserDialogComponent, dialogConfig);

      dialogRef.afterClosed()
        .subscribe({
          next: (dialogData: { confirmed: boolean; formData: { createType: string } }) => callback(dialogData),
        });

      return dialogRef;
    }
  }

  openBookingWorkBreakDialog(
    callback: (dialogData: { confirmed: boolean; formData: IWorkBreaksHeader }) => void,
    dialogConfig?: Partial<MatDialogConfig>,
  ): MatDialogRef<BookingWorkBreakDialogComponent> {
    const dialogRef = this.dialog.open(BookingWorkBreakDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe({
        next: (dialogData: { confirmed: boolean; formData: IWorkBreaksHeader }) => callback(dialogData),
      });

    return dialogRef;
  }

}
