import { ITDSIconGroup } from '../enums/itds-icons.enum';
import { ITDSIconGroups } from '../interfaces/itds-icons.interface';
import { Copy } from '../svg/files/copy';
import { Download } from '../svg/files/download';
import { Add } from '../svg/navigation/add';
import { AddSquare } from '../svg/navigation/add-square';
import { Caret } from '../svg/navigation/caret';
import { CheckSquare } from '../svg/status/check-square';
import { Checkmark } from '../svg/status/checkmark';
import { Information } from '../svg/status/Information';

/**
 * ITDS Icon set in groups
 * Figma: https://www.figma.com/design/WOSWwiLtSxupJFmOHbwAHw/%E2%AD%90-Icons---Interticket-Design-Language?node-id=1-5200&m=dev
 * Exports: https://drive.google.com/drive/folders/1VR0SXqRjLFUkrgri04J6Bgv_E4t-p0Ri
 */
export const ITDSIconPack: ITDSIconGroups = {

  // FILES
  [ITDSIconGroup.FILES]: {
    Copy,
    Download,
  },

  // NAVIGATION
  [ITDSIconGroup.NAVIGATION]: {
    Add,
    AddSquare,
    Caret,
  },

  // STATUS
  [ITDSIconGroup.STATUS]: {
    CheckSquare,
    Checkmark,
    Information,
  },

};
