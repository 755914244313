<div
  class="badge-wrapper flex-middle flex-center"
  [class.disabled]="isDisabled()"
  [class.has-hover]="enableHover()"
  [class.remain-circle]="isCircle()">

  <!-- VALUE -->
  <span class="badge-value flex-middle flex-center">
    {{ value() }}
  </span>

  <!-- HOVER STATE -->
  @if (enableHover()) {
    <span
      class="badge-hover flex-middle flex-center"
      (click)="hoverClick.emit()">

      <!-- HOVER ICON -->
      <ui-kit-icon
        [icon]="'x'"
        [size]="14" />

    </span>
  }

</div>
