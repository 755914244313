export const LinkOff = `<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon">
    <path id="Rectangle 191"
      d="M7.97449 10.3858L6.08049 12.2798C5.10834 13.2519 3.53218 13.2519 2.56003 12.2798V12.2798C1.58789 11.3076 1.58789 9.73145 2.56003 8.7593L4.45881 6.86053M10.2141 8.14616L12.1318 6.22849C13.1039 5.25634 13.1039 3.68018 12.1318 2.70803V2.70803C11.1596 1.73588 9.58345 1.73588 8.61131 2.70803L6.70676 4.61257"
      stroke="currentColor" stroke-width="1.5" />
    <g id="Group 185">
      <path id="Vector 382" d="M12.333 9.5H14.333" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 383" d="M9.33301 12.5V14.5" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 384" d="M11.4824 11.6484L13.1772 13.3466" stroke="currentColor" stroke-width="1.5" />
    </g>
    <g id="Group 186">
      <path id="Vector 382_2" d="M2.33301 5.5H0.333008" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 383_2" d="M5.33301 2.5V0.5" stroke="currentColor" stroke-width="1.5" />
      <path id="Vector 384_2" d="M3.18358 3.35155L1.48886 1.65337" stroke="currentColor"
        stroke-width="1.5" />
    </g>
  </g>
</svg>`;
