import { CustomRolePermission } from '@core/enums/permission/custom-roles-permission.enum';
import { PartnerPermission } from '@core/enums/permission/partner-permission.enum';
import { SystemRolePermission } from '@core/enums/permission/system-roles-permission.enum';
import { UserPermission } from '@core/enums/permission/user-permission.enum';
import { CardTemplatePermission, TicketTemplatePermission } from '@interticket/template-layout-editor-angular/permissions';
import { AreaOfInterestPermission, ArticleSettingsPermission, BlockchainPermission, BookingServiceCategoryPermissions, BookingServicePermission, BookingsPermission, BusinessDetailsPermission, CalendarPermission, CardPermission, ComfortCostPermission, ContentManagementPermission, DataPumpPipelinePermission, EmailComponentsPermission, EmailTemplatesPermission, EmailTypesPermission, EventPermission, ManageResourcesPermission, NotificationsPermission, OTRSManagementPermission, PartnerPageSettingsPermission, PartnersApiManagementPermission, PlaceCategoriesPermissions, PlacePermission, RatePermission, RefundPermission, RegionPageSettingsPermission, RegionsPermission, ReportingPermission, TransactionPermission, TranslationsManagementPermission, WalletPermission } from '@services/permissions';
import { BoSalesPermission } from 'app/modules/boxoffice/modules/sales/permissions/bo-sales-permission.enum';
import { DashboardPermission } from 'app/modules/dashboard/permissions/dashboard-permission.enum';
import { CategoryPermission } from 'app/modules/event/modules/categories/permissions/category-permission.enum';
import { DiscountPermission } from 'app/modules/event/modules/discount/permissions/discount-permission.enum';
import { AttachmentsPermission } from 'app/modules/partner-settings/modules/attachments/permissions/attachments-permissions.enum';
import { ReportPermission } from 'app/modules/report/modules/reports/permissions/report-permission.enum';
import { ReportCategoryPermissions } from 'app/modules/settings/report-category/permissions/report-category-permission.enum';
import { BankCardPermission } from 'app/modules/smart-city/bank-card/permissions/bank-card-permission.enum';
import { BannerPermission } from 'app/modules/smart-city/banner/permissions/banner-permission.enum';
import { SmartCityCardPermission } from 'app/modules/smart-city/card/permissions/card-permission.enum';
import { GovernmentPermission } from 'app/modules/smart-city/government/permissions/government-permission.enum';
import { MarketPermission } from 'app/modules/smart-city/market/permissions/market-permission.enum';
import { ParkingPermission } from 'app/modules/smart-city/parking/permissions/parking-permission.enum';
import { PlacePermission as SmartCityPlacePermission } from 'app/modules/smart-city/place/permissions/place-permission.enum';
import { ProductPermission } from 'app/modules/smart-city/product/permissions/product-permission.enum';
import { SmartCityReportPermission } from 'app/modules/smart-city/reports/permissions/report-permission.enum';
import { StepToStopPermission } from 'app/modules/smart-city/step-to-stop/permissions/step-to-stop.permission';
import { VignettePermission } from 'app/modules/smart-city/vignette/permissions/vignette-permission.enum';
import { AdmissionPermission } from 'app/modules/tools/admission/enums/admission-permission.enum';
import { SurveyPermission } from 'app/modules/tools/survey/permissions/survey-permission.enum';
import { ContractsPermission } from 'app/modules/user-management/modules/contracts/permissions/contracts-permission.enum';

export const userManagementPermissions: string[] = [
  UserPermission.LIST,
  PartnerPermission.LIST,
  PartnerPermission.CREATE_SMART_CITY_PARTNER,
  CustomRolePermission.LIST,
  ContractsPermission.LIST,
];

export const eventPermissions: string[] = [
  EventPermission.LIST_EVENTS,
  EventPermission.LIST_PROGRAMS,
  CategoryPermission.LIST,
  DiscountPermission.LIST,
];

export const ratePermissions: string[] = [
  RatePermission.FEE_LIST,
  RatePermission.FEE_CREATE,
];

export const boxofficePermissions: string[] = [
  BoSalesPermission.SELL_TICKET,
  RefundPermission.TICKETS,
  RefundPermission.STEM5_TICKETS,
];

const updatePartnerProfile: string[] = [
  PartnerPermission.PARTNER_GET_ME,
  PartnerPermission.PARTNER_UPDATE_ME,
];

export const reportPermissions: string[] = [
  ReportPermission.LIST,
  ReportingPermission.FINANCIAL_REPORT_LIST,
  ReportingPermission.USE_GIFT_CARD_REPORT,
];

export const customerSupportPermissions: string[] = [
  TransactionPermission.LIST,
];

export const administrationPermissions: string[] = [
  OTRSManagementPermission.LIST,
  RegionsPermission.LIST,
  SystemRolePermission.LIST,
];

export const partnerSettingsPermissions: string[] = [
  ComfortCostPermission.LIST,
  ComfortCostPermission.CREATE,
  AttachmentsPermission.UPLOAD,
];

export const partnersApiPermissions: string[] = [
  PartnersApiManagementPermission.MANAGE,
  PartnersApiManagementPermission.INTEGRATION,
];

export const dashboardPermissions: string[] = [
  DashboardPermission.PROFIT,
  DashboardPermission.REVENUE,
  DashboardPermission.SOLD_QUANTITY,
];

export const toolsPermissions: string[] = [
  AdmissionPermission.LIST,
  SurveyPermission.SURVEY_LIST,
];

export const recommendationPermissions: string[] = [
  RegionPageSettingsPermission.EDIT_LANDING_REGION_PAGE_BASIC,
];

export const partnerPageSettingsPermissions: string[] = [
  PartnerPageSettingsPermission.EDIT_LANDING,
  PartnerPageSettingsPermission.EDIT_LANDING_SPECIALS,
];

export const cardPermissions: string[] = [
  CardPermission.GIFT_CARD_CREATE,
  CardPermission.GIFT_CARD_LIST,
  CardPermission.GIFT_CARD_VIEW,
  CardPermission.GIFT_CARD_EDIT,
  CardPermission.ISSUED_GIFT_CARD_LIST,
  CardPermission.ISSUED_GIFT_CARD_EDIT,
  CardPermission.DISCOUNT_CARD_CREATE,
  CardPermission.DISCOUNT_CARD_LIST,
  CardPermission.DISCOUNT_CARD_VIEW,
  CardPermission.DISCOUNT_CARD_EDIT,
  CardPermission.ISSUED_DISCOUNT_CARD_LIST,
  CardPermission.ISSUED_DISCOUNT_CARD_EDIT,
  CardPermission.CUSTOM_CARD_CREATE,
  CardPermission.CUSTOM_CARD_LIST,
  CardPermission.CUSTOM_CARD_VIEW,
  CardPermission.CUSTOM_CARD_EDIT,
  CardPermission.ISSUED_CUSTOM_CARD_LIST,
  CardPermission.ISSUED_CUSTOM_CARD_EDIT,
  CardPermission.PERMIT_CARD_TEMPLATE_VIEW,
  CardPermission.PERMIT_CARD_TEMPLATE_CREATE,
  CardPermission.PERMIT_CARD_TEMPLATE_EDIT,
  CardPermission.PERMIT_CARD_REQUEST_LIST,
];

export const partnerSettings: string[] = [
  ReportCategoryPermissions.REPORT_CATEGORY_ADMIN_LIST,
  ArticleSettingsPermission.ARTICLE_SETTINGS_DETAILS,
  ContentManagementPermission.LIST,
  AreaOfInterestPermission.LIST,
  PlaceCategoriesPermissions.LIST,
];

export const smartCityPermissions: string[] = [
  BankCardPermission.BANK_CARD_LIST,
  BannerPermission.LIST,
  SmartCityCardPermission.LIST,
  WalletPermission.LIST,
  GovernmentPermission.LIST,
  MarketPermission.LIST,
  NotificationsPermission.LIST_ADMIN_NOTIFICATIONS,
  ParkingPermission.LIST,
  SmartCityPlacePermission.PLACE_LIST,
  ProductPermission.LIST,
  SmartCityReportPermission.REPORT_LIST,
  VignettePermission.LIST,
  StepToStopPermission.LIST,
];

export const translationManagementPermissions: string[] = [
  TranslationsManagementPermission.LIST,
  TranslationsManagementPermission.LIST_LANGUAGES,
  TranslationsManagementPermission.EXPORT,
  TranslationsManagementPermission.IMPORT,
  TranslationsManagementPermission.IMPORT_PLATFORM,
];

export const dataPumpPermissions: string[] = [
  DataPumpPipelinePermission.LIST,
];

export const htmlBuilderPermissions: string[] = [
  EmailTemplatesPermission.LIST,
  EmailTypesPermission.LIST,
  EmailComponentsPermission.LIST,
];

export const templatesPermissions: string[] = [
  TicketTemplatePermission.LIST,
  CardTemplatePermission.LIST,
];

export const placePermissions: string[] = [
  PlacePermission.LIST_AND_SEARCH,
  PlacePermission.VIEW_PLACE,
  PlacePermission.CREATE_NEW_PLACE,
  PlacePermission.CREATE_NON_SEATED_AUDITORIUM,
];

export const bookingPermissions: string[] = [
  BusinessDetailsPermission.BUSINESS_DETAILS_VIEW,
  ManageResourcesPermission.RESOURCES_LIST,
  BookingServiceCategoryPermissions.SERVICE_CATEGORIES_LIST,
  BookingServicePermission.LIST_SERVICE,
  BookingsPermission.BOOKINGS_LIST,
  CalendarPermission.CALENDAR_GET_BOOKINGS,
];

export const blockchainPermissions: string[] = [
  BlockchainPermission.BLOCKCHAIN_CONFIG_LIST,
  BlockchainPermission.SMART_CONTRACT_LIST,
  BlockchainPermission.SERVICE_LIST,
];

export const getModulePermissions: () => string[] = (): string[] =>
  userManagementPermissions
    .concat(eventPermissions)
    .concat(boxofficePermissions)
    .concat(updatePartnerProfile)
    .concat(reportPermissions)
    .concat(administrationPermissions)
    .concat(dashboardPermissions)
    .concat(toolsPermissions)
    .concat(recommendationPermissions)
    .concat(partnerPageSettingsPermissions)
    .concat(cardPermissions)
    .concat(partnerSettings)
    .concat(smartCityPermissions)
    .concat(translationManagementPermissions)
    .concat(ratePermissions)
    .concat(dataPumpPermissions)
    .concat(htmlBuilderPermissions)
    .concat(templatesPermissions)
    .concat(placePermissions)
    .concat(bookingPermissions)
    .concat(blockchainPermissions);
