export enum EventPermission {
  ADD_NEW_EVENT_TO_PROGRAM = 'programsv2.add.new.event.to.program.-.v2',
  ADD_RECURRING = 'recurring.add.recurring.add.recurring',
  ADD_TICKET = 'eventsv2.add.ticket.to.new.event.-.v2',
  ADD_TICKET_TEMPLATE = 'eventsv2.add.tickettemplate.to.new.event.-.v2',
  ASSIGN_CUSTOM_FIELDS = 'eventsv2.assign.custom.fields.to.event',
  ASSIGN_CUSTOM_FIELDS_PROGRAM = 'programsv2.assign.custom.fields.to.program.-.v2',
  CHANGE_TICKET_TEMPLATE_OF_EVENT = 'eventsv2.change.ticket.template.of.event.-.v2',
  COPY_TICKETS_FROM_EXISTING_EVENTS = 'eventsv2.copy.tickets.from.existing.events',
  CREATE = 'programsv2.add.basic.information.to.new.program.-.v2',
  CREATE_EVENT = 'eventsv2.add.basic.information.to.new.event.-.v2',
  CREATE_LIVE_STREAM_EVENT = 'eventsv2.create.live.stream.event.-.v2',
  CREATE_LIVE_STREAM_VIDEO_EVENT = 'eventsv2.create.live.stream.plus.video.event.-.v2',
  CREATE_NO_DATE_EVENT = 'eventsv2.create.no.date.event.-.v2',
  CREATE_TICKET_GROUP = 'eventsv2.create.ticket.group.-.v2',
  CREATE_TIME_SLOT = 'recurring.add.time.slot.create.time.slot',
  CREATE_VIDEO_EVENT = 'eventsv2.create.video.event.-.v2',
  DELETE_AND_RESTORE_PROGRAM = 'programsv2.delete.and.restore.program.-.v2',
  DELETE_EVENT = 'eventsv2.delete.event.-.v2',
  DOWNLOAD_QR_CODE = 'eventsv2.download.qr.code.for.simplified.purchase.flow.-.v2',
  EDIT_TIMESLOT = 'eventsv2.edit.edit.time.slots.-.v2',
  EVENT_EDIT_BASIC_INFO = 'eventsv2.edit.basic.information.of.event.-.v2',
  EVENT_EDIT_TICKET_OF_EVENT = 'eventsv2.edit.ticket.of.event.-.v2',
  GUEST_LIST_V2 = 'eventsv2.guest.list.-.v2',
  INHERIT_PROGRAM_MODIFICATIONS = 'programsv2.inherit.program.modifications.-.v2',
  LIST_EVENTS = 'eventsv2.list.events.-.v2',
  LIST_PROGRAMS = 'programsv2.list.programs.-.v2',
  MANAGE_VIDEO = 'eventsv2.upload.or.delete.video.to.event.-.v2',
  MANAGE_VIDEO_PROGRAM = 'programsv2.upload.and.delete.video.to.program.-.v2',
  NTAK_OBLIGED_EVENT = 'eventsv2.set.event.as.ntak.obliged.-.v2',
  NTAK_OBLIGED_PROGRAM = 'programsv2.set.program.as.ntak.obliged.-.v2',
  NTAK_VIEW_SETTINGS = 'programsv2.view.ntak.settings.-.v2',
  ORGANIZE_TICKETS = 'eventsv2.organize.tickets.-.v2',
  PROGRAM_EDIT_BASIC_INFO = 'programsv2.edit.basic.information.of.program.-.v2',
  REACH_PROGRAM_PAGE = 'programsv2.reach.program.page.-.v2',
  SAVE_PROGRAM_AND_EVENT_AS_DRAFT = 'programsv2.save.program.and.event.as.draft.-.v2',
  SET_REGIONAL_RESTRICTION = 'eventsv2.set.regional.restriction.for.event.-.v2',
  SET_TICKET_TEMPLATE_OF_TICKET = 'eventsv2.set.ticket.template.of.ticket.-.v2',
  SET_TICKET_VALIDITY_FOR_ONLINE_EVENTS = 'eventsv2.set.validity.period.of.tickets.for.online.events.-.v2',
  VIEW_CUSTOM_FIELDS = 'eventsv2.view.custom.fields.-.v2',
  VIEW_CUSTOM_FIELDS_PROGRAM = 'programsv2.view.custom.fields.-.v2',
  VIEW_EVENT = 'eventsv2.view.event.-.v2',
  VIEW_PROGRAM = 'programsv2.view.program.-.v2',
  WITHOUT_TICKET = 'eventsv2.create.event.without.ticket.-.v2',
}
