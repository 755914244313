import { GuestListExportField } from '../enums/event/guest-list-export-field.enum';
import { IEventGuestListExportColumns } from '../interfaces/event/event-guest-list-export-columns.interface';

export const guestListColumnMap: IEventGuestListExportColumns = {
  transactionColumns: {
    [GuestListExportField.BARCODE]: 'label_barcode_field',
    [GuestListExportField.DISCOUNT_NAME]: 'label_discount',
    [GuestListExportField.DISCOUNT_CODE]: 'label_discount_code',
    [GuestListExportField.ORIGINAL_PRICE]: 'label_original_price',
    [GuestListExportField.PAID_AMOUNT]: 'label_paid_amount',
    [GuestListExportField.PURCHASE_DATE]: 'label_purchase_date',
    [GuestListExportField.PRICE_CATEGORY_NAME]: 'programs_pricing_name',
    [GuestListExportField.TRANSACTION_ID]: 'lang_transaction_id',
  },
  customerColumns: {
    [GuestListExportField.ADDRESS]: 'stemx_boxoffice_address',
    [GuestListExportField.CITY]: 'stemx_boxoffice_city',
    [GuestListExportField.COUNTRY]: 'stemx_boxoffice_country',
    [GuestListExportField.EMAIL]: 'buyer_email',
    [GuestListExportField.FIRST_NAME]: 'buyer_first_name',
    [GuestListExportField.LAST_NAME]: 'buyer_last_name',
    [GuestListExportField.PHONE]: 'stemx_boxoffice_phone',
    [GuestListExportField.ZIPCODE]: 'stemx_boxoffice_zip_code',
  },
  holderColumns: {
    [GuestListExportField.HOLDER_EMAIL]: 'holder_email',
    [GuestListExportField.HOLDER_FIRST_NAME]: 'holder_firstname',
    [GuestListExportField.HOLDER_LAST_NAME]: 'holder_lastname',
  },
};
