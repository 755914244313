import { ChangeDetectionStrategy, Component, computed, HostListener, inject, input, output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-icon-card',
  templateUrl: './ui-kit-icon-card.component.html',
  styleUrls: ['./ui-kit-icon-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[style]': 'this.paletteColors' },
})
export class UiKitIconCardComponent {

  private readonly sanitizer = inject(DomSanitizer);

  readonly icon = input.required<string>();
  readonly iconSize = input<number>();
  readonly title = input.required<string>();
  readonly palette = input<UiKitPalette>();
  readonly size = input<UiKitSize>(UiKitSize.MEDIUM);
  readonly description = input<string>();
  readonly isSelected = input<boolean>();
  readonly isDisabled = input<boolean>();
  readonly isColumnLayout = input<boolean>(false);
  readonly isVisibleCornerIndicator = input<boolean>(false);

  readonly onClick = output<void>();

  readonly fallbackIconSize = computed(() => this.size() === UiKitSize.MEDIUM ? 18 : 24);

  get paletteColors(): SafeStyle {
    const styles: string[] = [];

    if (this.palette()) {
      styles.push(
        `--card-color: var(--${this.palette()}-color);`,
        `--card-color-active: var(--${this.palette()}-color-active);`,
        `--card-color-active-outline: var(--${this.palette()}-color-active);`,
        `--card-color-background: var(--${this.palette()}-color-background);`
      );
    }

    return this.sanitizer.bypassSecurityTrustStyle(styles.join('\n'));
  }

  @HostListener('click') handleClick(): void {
    !this.isDisabled() && this.onClick.emit();
  }

}
