export const CalendarDates = `<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon">
    <rect id="Rectangle 164" x="2.16602" y="5.48047" width="33" height="33" rx="3.92717" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 333" d="M2.16602 14.4805H35.166" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 334" d="M26.1641 9.98047V0.980469" stroke="currentColor" stroke-width="3"/>
    <path id="Vector 335" d="M11.165 9.98047V0.980469" stroke="currentColor" stroke-width="3"/>
    <path id="Vector" d="M10.7539 23.6874C10.4017 23.6874 10.1162 23.4019 10.1162 23.0498C10.1162 22.6976 10.4017 22.4121 10.7539 22.4121C11.106 22.4121 11.3915 22.6976 11.3915 23.0498C11.3915 23.4019 11.106 23.6874 10.7539 23.6874Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
    <path id="Vector_2" d="M10.7539 32.2382C10.4017 32.2382 10.1162 31.9527 10.1162 31.6005C10.1162 31.2484 10.4017 30.9629 10.7539 30.9629C11.106 30.9629 11.3915 31.2484 11.3915 31.6005C11.3915 31.9527 11.106 32.2382 10.7539 32.2382Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
    <path id="Vector_3" d="M19.3027 32.2382C18.9505 32.2382 18.665 31.9527 18.665 31.6005C18.665 31.2483 18.9505 30.9629 19.3027 30.9629C19.6549 30.9629 19.9403 31.2483 19.9403 31.6005C19.9403 31.9527 19.6549 32.2382 19.3027 32.2382Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
    <path id="Vector_4" d="M27.8515 32.2382C27.4993 32.2382 27.2139 31.9527 27.2139 31.6005C27.2139 31.2483 27.4993 30.9629 27.8515 30.9629C28.2037 30.9629 28.4892 31.2483 28.4892 31.6005C28.4892 31.9527 28.2037 32.2382 27.8515 32.2382Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
    <path id="Vector_5" d="M19.3027 23.6874C18.9505 23.6874 18.665 23.402 18.665 23.0498C18.665 22.6976 18.9505 22.4121 19.3027 22.4121C19.6549 22.4121 19.9403 22.6976 19.9403 23.0498C19.9403 23.402 19.6549 23.6874 19.3027 23.6874Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
    <path id="Vector_6" d="M27.8515 23.6874C27.4993 23.6874 27.2139 23.402 27.2139 23.0498C27.2139 22.6976 27.4993 22.4121 27.8515 22.4121C28.2037 22.4121 28.4892 22.6976 28.4892 23.0498C28.4892 23.402 28.2037 23.6874 27.8515 23.6874Z" fill="currentColor" stroke="currentColor" stroke-width="3"/>
  </g>
</svg>
`;
