export const EyeOff = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M6.99029 2.1875H7.0006C7.74192 2.1875 8.74121 2.36275 9.42623 2.69518L8.75602 3.37925C8.18401 3.19169 7.64627 3.06438 7.01075 3.06251C4.06443 3.12642 1.99159 5.32471 1.34626 7.00526C1.42726 7.20803 1.57028 7.53206 1.79085 7.8768C2.07382 8.31905 2.45812 8.82007 2.91955 9.22681L2.29577 9.85059C1.82277 9.43454 1.38257 8.86221 1.05381 8.34838C0.738069 7.85491 0.559438 7.40174 0.483856 7.20137C0.43457 7.07071 0.434183 6.92443 0.488119 6.79042C1.26443 4.86157 3.37585 2.35784 6.98 2.18799L6.99029 2.1875ZM12.0242 5.8728C11.568 5.23649 10.9258 4.52835 10.1688 4.05015L10.6361 3.3104C11.5211 3.8695 12.2405 4.67286 12.7353 5.36291C12.9841 5.7099 13.1817 6.03599 13.3191 6.30003C13.3876 6.43159 13.4437 6.55276 13.484 6.65688C13.5164 6.74068 13.5631 6.87275 13.5631 7C13.5631 7.0945 13.5388 7.18614 13.5198 7.24812C13.4977 7.32025 13.4673 7.40005 13.4309 7.48416C13.3578 7.65301 13.2523 7.85819 13.1162 8.08506C12.8439 8.5391 12.4389 9.09692 11.9008 9.64147C10.825 10.73 9.19001 11.7902 7.00506 11.8125L7.0006 11.8125C5.64068 11.8125 4.39156 11.3375 3.36784 10.6913L3.83487 9.9514C4.75567 10.5326 5.8439 10.937 6.99835 10.9375C8.8829 10.9176 10.3097 10.0066 11.2784 9.02641C11.7627 8.53641 12.1257 8.03528 12.3658 7.63499C12.4859 7.4347 12.5727 7.26388 12.6279 7.13651C12.6523 7.08002 12.669 7.03571 12.6794 7.00373C12.6764 6.99509 12.6726 6.98474 12.6679 6.97254C12.6426 6.90721 12.6015 6.81647 12.5429 6.70393C12.4263 6.47979 12.251 6.18898 12.0242 5.8728Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M7.67277 4.46201C7.45806 4.40524 7.23256 4.375 7 4.375C5.55025 4.375 4.375 5.55025 4.375 7C4.375 7.23256 4.40524 7.45806 4.46201 7.67278L7.67277 4.46201Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M6.25342 7.84102L5.23486 8.85957C5.21823 8.8762 5.2104 8.89963 5.21359 8.92284C5.68209 9.35801 6.30979 9.62411 6.99964 9.62411C8.44939 9.62411 9.62464 8.44886 9.62464 6.99911C9.62464 6.29857 9.35022 5.66213 8.90302 5.19141L7.84154 6.25289C8.01768 6.45146 8.12464 6.7128 8.12464 6.99911C8.12464 7.62044 7.62096 8.12411 6.99964 8.12411C6.71333 8.12411 6.45199 8.01716 6.25342 7.84102Z"
    fill="currentColor" />
  <path d="M12.8172 1.17382L1.18555 12.8171" stroke="currentColor" />
</svg>`;
