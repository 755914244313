import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { UiKitIconsModule } from '../icons';
import { UiKitButtonComponent } from './components/ui-kit-button/ui-kit-button.component';
import { UiKitButtonClearFiltersComponent } from './components/ui-kit-button-clear-filters/ui-kit-button-clear-filters.component';
import { UiKitButtonIconComponent } from './components/ui-kit-button-icon/ui-kit-button-icon.component';
import { UiKitIconCardComponent } from './components/ui-kit-icon-card/ui-kit-icon-card.component';
import { UiKitIconLinkComponent } from './components/ui-kit-icon-link/ui-kit-icon-link.component';
import { UiKitStatusBadgeComponent } from './components/ui-kit-status-badge/ui-kit-status-badge.component';

const components = [
  UiKitButtonComponent,
  UiKitButtonIconComponent,
  UiKitButtonClearFiltersComponent,
  UiKitIconCardComponent,
  UiKitIconLinkComponent,
  UiKitStatusBadgeComponent,
];

@NgModule({
  imports: [
    SharedModule,
    UiKitIconsModule,
    RouterModule,

    // Material imports
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
})
export class UiKitControlsModule { }
