export const OneItem = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_13970_40852" fill="white">
<rect x="0.5" y="0.5" width="15" height="15" rx="1"/>
</mask>
<rect x="0.5" y="0.5" width="15" height="15" rx="1" stroke="currentColor" stroke-width="3" mask="url(#path-1-inside-1_13970_40852)"/>
<mask id="path-2-inside-2_13970_40852" fill="white">
<path d="M8.625 9.875V4.875H7.375V5.5H6.125V6.75H7.375V9.875H6.125V11.125H9.875V9.875H8.625Z"/>
</mask>
<path d="M8.625 9.875V4.875H7.375V5.5H6.125V6.75H7.375V9.875H6.125V11.125H9.875V9.875H8.625Z" fill="currentColor"/>
<path d="M8.625 9.875H7.125V11.375H8.625V9.875ZM8.625 4.875H10.125V3.375H8.625V4.875ZM7.375 4.875V3.375H5.875V4.875H7.375ZM7.375 5.5V7H8.875V5.5H7.375ZM6.125 5.5V4H4.625V5.5H6.125ZM6.125 6.75H4.625V8.25H6.125V6.75ZM7.375 6.75H8.875V5.25H7.375V6.75ZM7.375 9.875V11.375H8.875V9.875H7.375ZM6.125 9.875V8.375H4.625V9.875H6.125ZM6.125 11.125H4.625V12.625H6.125V11.125ZM9.875 11.125V12.625H11.375V11.125H9.875ZM9.875 9.875H11.375V8.375H9.875V9.875ZM10.125 9.875V4.875H7.125V9.875H10.125ZM8.625 3.375H7.375V6.375H8.625V3.375ZM5.875 4.875V5.5H8.875V4.875H5.875ZM7.375 4H6.125V7H7.375V4ZM4.625 5.5V6.75H7.625V5.5H4.625ZM6.125 8.25H7.375V5.25H6.125V8.25ZM5.875 6.75V9.875H8.875V6.75H5.875ZM7.375 8.375H6.125V11.375H7.375V8.375ZM4.625 9.875V11.125H7.625V9.875H4.625ZM6.125 12.625H9.875V9.625H6.125V12.625ZM11.375 11.125V9.875H8.375V11.125H11.375ZM9.875 8.375H8.625V11.375H9.875V8.375Z" fill="currentColor" mask="url(#path-2-inside-2_13970_40852)"/>
</svg>`;
