import { ChangeDetectionStrategy, Component, computed, EventEmitter, HostBinding, input, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-badge',
  templateUrl: './ui-kit-badge.component.html',
  styleUrls: ['./ui-kit-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitBadgeComponent {

  readonly enableHover = computed(() => {
    if (this.hasHover() === undefined) {
      return !this.isDisabled() && this.hoverClick.observed;
    } else {
      return this.hasHover();
    }
  });

  readonly value = input<string | number>();
  readonly palette = input<UiKitPalette>(UiKitPalette.PRIMARY);
  readonly isCircle = input<boolean>(true);
  readonly isDisabled = input<boolean>(false);
  readonly hasHover = input<boolean>();

  @Output() readonly hoverClick = new EventEmitter<void>();

  @HostBinding('style') get paletteColors(): SafeStyle {
    const palette: UiKitPalette = this.palette() || UiKitPalette.PRIMARY;
    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-badge-color: var(--${palette}-color);
    `);
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

}
