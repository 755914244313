import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { EventType } from '@services/product-catalog';
import { UiKitPalette } from '@ui-kit/enums';

type UiKitEventTypeDisplayModes = 'normal' | 'sidebar' | 'event-list-item';

@Component({
  selector: 'ui-kit-event-type',
  templateUrl: './ui-kit-event-type.component.html',
  styleUrls: ['./ui-kit-event-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitEventTypeComponent {

  readonly eventType = input.required<EventType>();
  readonly displayMode = input<UiKitEventTypeDisplayModes>('normal');
  readonly infoMessageLabel = input<string>();
  readonly palette = input<UiKitPalette>(UiKitPalette.PRIMARY);
  readonly isBold = input<boolean>(false);

  readonly eventTypes = EventType;

  private readonly defaultIcon = 'on-site';
  private readonly iconMap = {
    [EventType.NORMAL]: this.defaultIcon,
    [EventType.VIDEO]: 'video-stream',
    [EventType.LIVESTREAM]: 'live-stream',
    [EventType.LIVESTREAM_VIDEO]: 'live-stream',
  };

  readonly normalizedEventType = computed(() => {
    const type = this.eventType();
    return type ? (type.toLowerCase() as EventType) : EventType.NORMAL;
  });

  readonly icon = computed(() => this.getIcon(this.normalizedEventType()));

  getIcon(type: EventType): string {
    return this.iconMap[type] || this.defaultIcon;
  }

}
