<div
  matRipple
  class="card-container ui-kit-size-{{ size() }}"
  [class.is-selected]="isSelected()"
  [class.is-disabled]="isDisabled()"
  [class.is-column]="isColumnLayout()"
  [matRippleDisabled]="isDisabled()">

  <!-- ICON -->
  <div class="card-icon">
    <ui-kit-icon
      [icon]="icon()"
      [size]="iconSize() ?? fallbackIconSize()" />
  </div>

  <div class="d-flex flex-column">

    <!-- TITLE -->
    <div class="card-title">{{ title() | translate }}</div>

    <!-- DESCRIPTION -->
    @if (description()) {
      <div class="card-description">{{ description() | translate }}</div>
    }

  </div>

  @if (isVisibleCornerIndicator()) {
    <ui-kit-icon
      class="corner-indicator"
      [icon]="isSelected() ? 'minus-circle' : 'plus-circle-filled'"
      [size]="20" />
  }

</div>
