import { ICrispConfig } from '@interticket/core';
import { Environment } from '../enums/environment.enum';
import { IDynamicAppConfigs, IStaticAppConfig } from '../interfaces/app-config.interface';

const slackWebhookUrl = 'https://hooks.slack.com/services/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';

const stackdriverConfig = {
  stackdriverApiKey: 'AIzaSyCRo7Sj4k4Scq-1QfyNhjheMx4CmhQEfVg',
  stackdriverProjectId: 'stemx-228313',
};

const stackdriverConfigStemxDev = {
  stackdriverApiKey: 'AIzaSyDYPYl9I54IzO2FQFsqHBbE9bniRS8d9sA',
  stackdriverProjectId: 'stemx-dev',
};

const crisp: ICrispConfig = {
  websiteId: '73a8b275-4d20-4fb4-8e27-5bd6d91719ab',
  cookieDomain: 'stx.interticket.com',
  safeMode: true,
};

export const StaticAppConfig: IStaticAppConfig = {
  googleMapsKey: 'AIzaSyDC7YgMaXFUQ77R64hvdFIXPiYHFbnxytA',
  reportWebhookUrl: 'https://hooks.slack.com/services/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',
  serviceUrl: '/api',
  translationTags: ['STXAdmin'],
};

export const AppConfigs: IDynamicAppConfigs = {
  accountSiteUrl: 'https://qa-accounts.stx.interticket.com',
  partnersApiDocsUrl: 'https://partners-api-ext.stx.interticket.com',
  cdnReferenceUrl: 'https://cdndev.stx.interticket.com',
  scEditorCDN: 'https://seating-chart-editordev.stx.interticket.com',
  scViewerCDN: 'https://seating-chartdev.stx.interticket.com',

  [Environment.PRODUCTION]: {
    accountSiteUrl: 'https://accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://api.stemxplatform.com',
    cdnReferenceUrl: 'https://cdn.stx.interticket.com',
    scEditorCDN: 'https://seating-chart-editor.stx.interticket.com',
    scViewerCDN: 'https://seating-chart.stx.interticket.com',
    crisp,
    ...stackdriverConfig,
  },

  [Environment.USA_PRODUCTION]: {
    accountSiteUrl: 'https://accounts.us.stx.interticket.com',
    partnersApiDocsUrl: 'https://partners-api-us.stx.interticket.com',
    cdnReferenceUrl: 'https://cdn.stx.interticket.com',
    scEditorCDN: 'https://seating-chart-editor.stx.interticket.com',
    scViewerCDN: 'https://seating-chart.stx.interticket.com',
    crisp,
    ...stackdriverConfig,
  },

  [Environment.PRODLIKE]: {
    accountSiteUrl: 'https://prodlike-accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://sandbox-api.stemxplatform.com',
    slackWebhookUrl,
    storybookBranch: 'master',
    ...stackdriverConfigStemxDev,
  },

  [Environment.DEVELOPMENT]: {
    accountSiteUrl: 'https://dev-accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://partners-api-dev.stx.interticket.com',
    slackWebhookUrl,
    storybookBranch: 'dev2',
    ...stackdriverConfigStemxDev,
  },

  [Environment.QA]: {
    accountSiteUrl: 'https://qa-accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://partners-api-qa.stx.interticket.com',
    slackWebhookUrl,
    storybookBranch: 'qa',
    ...stackdriverConfigStemxDev,
  },

  [Environment.EXT]: {
    accountSiteUrl: 'https://ext-accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://partners-api-ext.stx.interticket.com',
    slackWebhookUrl,
    ...stackdriverConfigStemxDev,
  },

  [Environment.EXT2]: {
    accountSiteUrl: 'https://ext2-accounts.stx.interticket.com',
    partnersApiDocsUrl: 'https://ext2-partners-docs.stx.interticket.com',
    slackWebhookUrl,
    ...stackdriverConfigStemxDev,
  },

  [Environment.LOCAL]: {
    scEditorCDN: 'editor',
    scViewerCDN: 'viewer',
    storybookBranch: 'qa',
  },

};
