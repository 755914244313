import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-label',
  templateUrl: './ui-kit-label.component.html',
  styleUrls: ['./ui-kit-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitLabelComponent {

  @Input()
  @HostBinding('class')
  readonly palette: UiKitPalette = UiKitPalette.DEFAULT;

  @Input()
  @HostBinding('class.is-rounded')
  readonly isRounded: boolean = false;

  @Input()
  @HostBinding('class.light')
  readonly isLight: boolean = false;

  @Input()
  @HostBinding('class.is-outline')
  readonly isOutline: boolean = false;

}
