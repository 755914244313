import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FormModule, MaterialModule as CoreMaterialModule, PipeModule, TranslateModule } from '@interticket/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonComponent } from './components/buttons/button.component';
import { CancelButtonComponent } from './components/buttons/cancel-button.component';
import { DeleteButtonComponent } from './components/buttons/delete-button.component';
import { DownloadButtonComponent } from './components/buttons/download-button.component';
import { DraftButtonComponent } from './components/buttons/draft-button.component';
import { GenerateButtonComponent } from './components/buttons/generate-button.component';
import { NewButtonComponent } from './components/buttons/new-button.component';
import { NextButtonComponent } from './components/buttons/next-button.component';
import { ResetButtonComponent } from './components/buttons/reset-button.component';
import { SaveButtonComponent } from './components/buttons/save-button.component';
import { SearchButtonComponent } from './components/buttons/search-button.component';
import { UploadButtonComponent } from './components/buttons/upload-button/upload-button.component';
import { CalloutComponent } from './components/callout/callout.component';
import { CardReasonModalComponent } from './components/card-reason-modal/card-reason-modal.component';
import { CheckableSearchBarComponent } from './components/checkable-search-bar/checkable-search-bar.component';
import { CheckboxChipDropdownComponent } from './components/checkbox-chip-dropdown/checkbox-chip-dropdown.component';
import { CheckboxSearchBarComponent } from './components/checkbox-search-bar/checkbox-search-bar.component';
import { ChipInputComponent } from './components/chip-input/chip-input.component';
import { CustomSidebarComponent } from './components/custom-sidebar/custom-sidebar.component';
import { CustomSidebarBackdropComponent } from './components/custom-sidebar-backdrop/custom-sidebar-backdrop.component';
import { IconPickerComponent } from './components/icon-picker/icon-picker.component';
import { InputGroupButtonComponent } from './components/input-group-button/input-group-button.component';
import { LengthErrorComponent } from './components/length-error/length-error-field.component';
import { ModuleRouterComponent } from './components/module-router/module.router';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { DatatableImageComponent } from './components/tables/datatable-image/datatable-image.component';
import { DatatableMessageComponent } from './components/tables/datatable-message/datatable-message.component';
import { DatatableSpinnerComponent } from './components/tables/datatable-spinner/datatable-spinner.component';
import { TabErrorLabelComponent } from './components/tabs/tab-error-label/tab-error-label.component';
import { TooltipIconComponent } from './components/tooltip-icon/tooltip-icon.component';
import { CapacityPipe } from './pipes/capacity.pipe';
import { ChangeCounterPipe } from './pipes/change-counter.pipe';
import { PartnerMonogramPipe } from './pipes/partner-monogram.pipe';
import { PasswordPipe } from './pipes/password.pipe';
import { TaxClassPipe } from './pipes/tax-class.pipe';
import { TimezonePipe } from './pipes/timezone.pipe';

// Interticket modules
const interticketModules = [
  FormModule,
  TranslateModule,
  PipeModule,
  CoreMaterialModule,
];

// Components
const components = [
  ModuleRouterComponent,
  CalloutComponent,
  TooltipIconComponent,
  ButtonComponent,
  NewButtonComponent,
  CancelButtonComponent,
  SaveButtonComponent,
  DeleteButtonComponent,
  SearchButtonComponent,
  ResetButtonComponent,
  NextButtonComponent,
  DownloadButtonComponent,
  DraftButtonComponent,
  UploadButtonComponent,
  GenerateButtonComponent,
  StatusIconComponent,
  DatatableMessageComponent,
  DatatableSpinnerComponent,
  DatatableImageComponent,
  CustomSidebarComponent,
  IconPickerComponent,
  CustomSidebarBackdropComponent,
  TabErrorLabelComponent,
  CardReasonModalComponent,
  LengthErrorComponent,
  CheckableSearchBarComponent,
  CheckboxChipDropdownComponent,
  CheckboxSearchBarComponent,
  InputGroupButtonComponent,
  ChipInputComponent,
  StatusBadgeComponent,
];

// Pipes
const pipes = [
  TimezonePipe,
  TaxClassPipe,
  PartnerMonogramPipe,
  PasswordPipe,
  CapacityPipe,
  ChangeCounterPipe,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    ...interticketModules,

    // Material imports
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    MatIconModule,
  ],
  declarations: [
    ...components,
    ...pipes,
  ],
  exports: [
    CommonModule,
    MatIconModule,
    ...interticketModules,
    ...components,
    ...pipes,
  ],
})
export class SharedModule { }
