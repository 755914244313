export const VideoStream = `<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group 235">
    <g id="icon">
      <path id="Rectangle 121" d="M8.56828 2.46875H10.9375C11.4898 2.46875 11.9375 2.91647 11.9375 3.46875V6.21193C11.9375 6.29327 12.0294 6.34058 12.0956 6.2933L15.7169 3.7067C15.7831 3.65942 15.875 3.70673 15.875 3.78807V11.2744C15.875 11.3558 15.7831 11.4031 15.7169 11.3558L12.0956 8.7692C12.0294 8.72192 11.9375 8.76923 11.9375 8.85057V11.5937C11.9375 12.146 11.4898 12.5937 10.9375 12.5937L2.25 12.5937C1.69772 12.5937 1.25 12.146 1.25 11.5937V8.09694" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    </g>
    <g id="Group 217">
      <circle id="Ellipse 26" cx="4.34375" cy="4.4375" r="1.40625" fill="currentColor"/>
      <circle id="Ellipse 27" cx="4.34375" cy="4.4375" r="2.90625" stroke="currentColor" stroke-width="1.5"/>
    </g>
  </g>
</svg>`;
