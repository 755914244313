import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { GoogleMapsService } from '@core/services/google-maps/google-map.service';
import { environment } from '@env';
import { AuthModule as CoreAuthModule, DATE_TIME_FORMATS, HttpModule, PipeModule, ScriptLoaderModule, TranslateModule, TranslateService } from '@interticket/core';
import { ITDSIconPack, ITDSIconsToken } from '@itds/icons';
import { IUiKitDateTimePickerModuleConfig, UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG, UiKitDateTimeModule } from '@ui-kit/date-time';
import { AuthGuard } from './security/auth-guard.service';
import { NoAuthGuard } from './security/no-auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { ErrorService } from './services/error-handler/error.service';
import { ErrorLoggerService } from './services/error-handler/error-logger.service';
import { ErrorReportService } from './services/error-handler/error-report.service';
import { NotifierService } from './services/notification/notifier.service';
import { AuthUserStoreService } from './services/store/auth-user-store.service';

@NgModule({
  imports: [
    CommonModule,
    ScriptLoaderModule,
    CoreAuthModule,
    HttpModule.forRoot(),
    TranslateModule.forRoot({ appVersion: environment.version }),
    PipeModule.forRoot(),
    UiKitDateTimeModule,
  ],
  providers: [
    ErrorService,
    ErrorLoggerService,
    ErrorReportService,
    AuthGuard,
    NoAuthGuard,
    AuthService,
    NotifierService,
    GoogleMapsService,
    { provide: ITDSIconsToken, useValue: Object.assign({}, ...(Object.values(ITDSIconPack))) },
    {
      provide: UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG,
      useFactory: (translate: TranslateService, authUserStore: AuthUserStoreService): IUiKitDateTimePickerModuleConfig => ({
        locale: translate.currentLang,
        format: DATE_TIME_FORMATS[translate.currentLang],
        timeZone: authUserStore.defaultTimezone,
      }),
      deps: [TranslateService, AuthUserStoreService],
    },
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}
