export const PersonEntry = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon">
    <g id="Group 230">
      <path id="Ellipse 99" d="M6.01744 2.35345C6.01744 2.89383 5.57938 3.3319 5.039 3.3319C4.49861 3.3319 4.06055 2.89383 4.06055 2.35345C4.06055 1.81307 4.49861 1.375 5.039 1.375C5.57938 1.375 6.01744 1.81307 6.01744 2.35345Z" stroke="currentColor" />
      <path id="Vector 549" d="M1.44922 8.05607V5.944L3.08626 4.96177C3.3971 4.77527 3.75277 4.67676 4.11526 4.67676H4.74171C5.55952 4.67676 6.29493 5.17466 6.59866 5.93398L6.9406 6.78883H9.05267" stroke="currentColor" />
      <path id="Vector 550" d="M3.56224 4.67676V10.5906L2.03125 12.8954" stroke="currentColor" />
      <path id="Vector 551" d="M5.67383 8.05566L6.51866 10.5901V13.1246" stroke="currentColor" />
    </g>
    <path id="Vector 239" d="M7.875 3.0625V2.3125C7.875 1.76022 8.32272 1.3125 8.875 1.3125H11.6875C12.2398 1.3125 12.6875 1.76022 12.6875 2.3125V11.6875C12.6875 12.2398 12.2398 12.6875 11.6875 12.6875H7.875" stroke="currentColor" />
  </g>
</svg>`;
